import React, { Component } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import Slider from './../Element/Slider';
import Accord from './../Element/Accord';

import { Helmet } from "react-helmet";

//Images	
var img1 = require('./../../images/background/bg5.webp');
var serblog1 = require('./../../images/our-services/pic1.webp');
var serblog2 = require('./../../images/our-services/pic2.webp');
var serblog3 = require('./../../images/our-services/pic3.webp');
var serblog4 = require('./../../images/our-services/pic4.webp');
var img2 = require('./../../images/background/bg1.webp');
var img3 = require('./../../images/background/bg5.webp');
var img4 = require('./../../images/background/bg4.webp');



class Index1 extends Component {
	render() {
		return (
			<>

				<Helmet>
					<title>Habibi Yachts | Yachts and Events Company</title>
					<link rel="canonical" href="https://www.habibiyachts.com/" />
					<meta name="description" content="Habibi Yachts are not just a yacht rental company, we are an extraordinary luxury event & luxury yacht rental planning company with a complete package of moments and celebrations under one platform. We have UAE's best Yachts Charter services with  Sunseeker & Benetti Yachts. Jetski & Seabob. " />
					<meta name="keywords" content="habibi Yachts, habibi Yachts Dubai,habibi.com,habibiyachts.com,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts to rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht to rent dubai,
yachts dubai rent" />
				</Helmet>
				<Header />

				<div className="page-content bg-white">
					<div className="content-block">
						<Slider />
						<div className="section-full content-inner-3" style={{ backgroundImage: "url(" + img1 + ")", backgroundSize: "100%" }}>
							<div className="container">
								<div className="row service-area1">
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + serblog1 + ")" }}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Our Yachts</h2>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/yachts')} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + serblog2 + ")" }}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Decorations</h2>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/decorations')} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + serblog3 + ")" }}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Yachts Marriages</h2>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/decorations')} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{ backgroundImage: "url(" + serblog4 + ")" }}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Surprices</h2>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/gifts')} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="section-head mb-0 text-center">
											<div className="icon-bx icon-bx-xl">
												<img src={require('./../../images/cake1.jpg')} alt="" />
											</div>
											<h3 className="text-primary">It’s a party and you’re invited</h3>
											<p className="main-text">Join  <strong>Habibi Yachts</strong>™ to enjoy your journey with top yachts with Cakes and food, get free refills, pay and order with your phone, and more.</p>
											<p>Exclusive deals on Yachts,Cakes,Decorations will delivered straight to your inbox <Link to={''}>Join Now</Link></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-full content-inner service-area2 bg-img-fix bg-line-top bg-line-bottom" style={{ backgroundImage: "url(" + img4 + ")", backgroundSize: "cover" }}>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="section-head text-center">
											<h2 className="text-white">What Do We Offer For You?</h2>
											<p className="text-bold">We provide yachts Rental sevices alone Occianal Decorations</p>
											<p className="text-bold">We alse provide online gifts sevices with free Delivery </p>
											<div className="dlab-separator style1 bg-primary"></div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 m-b30">
										<img src={require('./../../images/about/pic1.webp')} className="img-cover radius-sm" alt="" />
									</div>
									<div className="col-lg-8">
										<div className="row p-l30">
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
														<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon2.png')} alt="" /></Link>
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Barbecue</h5>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
														<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon3.png')} alt="" /></Link>
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Muffin</h5>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
														<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon4.png')} alt="" /></Link>
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Dinner/Lunch</h5>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
														<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon5.png')} alt="" /></Link>
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Drinks</h5>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
														<Link to={''} className="icon-cell"><img src={require('./../../images/icons/service-icon/icon1.png')} alt="" /></Link>
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Cakes</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-full content-inner bg-gray" style={{ backgroundImage: "url(" + img2 + ")", backgroundSize: "100%" }}>
							<div className="container">
								<div className="row faq-area1">
									<div className="col-lg-6 m-b30">
										<div className="m-r20">
											<div className="section-head text-left">
												<h2>Delivery Points</h2>
												<p className="text-bold">Where we deliver our high quality Cakes, Flowers and Ballon Bouquets</p>
												<div className="dlab-separator style1 bg-primary"></div>
											</div>
											<div className="clearfix">
												<p>Cakes,Flowers,Ballon Bouquets and Surprise boxes are requisite to celebrate every occasion. It adds so much of sweetness and delicacy in one’s life. Trigger those delightful moments in your loved ones lives by sending them best <strong>Cakes,Flowers,Ballon Bouquets and Surprise boxes</strong> online.</p>
												<p className="text">Want to surprise your loved ones with a special gifts? Be it their birthdays or any other occasions, you can present Our best surprises to show your love. It contains a cute teddy bear, white and red roses and cadbury dairy milk chocolates, ballons etc.</p>
												<Link to={'/Gifts'} className="btn btn-md btnhover shadow m-t30"><i className="fa fa-angle-right m-r10"></i>Get Started</Link>
											</div>
										</div>
									</div>
									<div className="col-lg-6 m-b30">
										<Accord />
									</div>
								</div>
							</div>
						</div>
						<div className="section-full bg-white">
							<div className="container-fluid">
								<div className="row">
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic1.webp')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Room Decorations</h2>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic2.webp')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Desert Safari</h2>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic3.webp')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Jetski</h2>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white m-md-b0 m-sm-b0">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic4.webp')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Flyboard</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-full bg-white" style={{ backgroundImage: "url(" + img3 + ")", backgroundSize: "100%" }}>
							<div className="container content-inner">
								<div className="row">
									<div className="col-lg-12">
										<div className="section-head text-center">
											<div className="icon-bx icon-bx-xl">
												<img style={{ width: '100%', height: "200px" }} src={require('./../../images/yacht.png')} alt="" />
											</div>
											<h3>We Are Professional at Our Skills</h3>
											<p>More than 2000+ customers trusted us</p>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
										<div className="counter-style-1 text-center">
											<div className="counter-num text-primary">
												<span className="counter"><CountUp end={5} /></span>
												<small>+</small>
											</div>
											<span className="counter-text">Years of Experience</span>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
										<div className="counter-style-1 text-center">
											<div className="counter-num text-primary">
												<span className="counter"><CountUp end={1000} /></span>
												<small>+</small>
											</div>
											<span className="counter-text">Bookings</span>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
										<div className="counter-style-1 text-center">
											<div className="counter-num text-primary">
												<span className="counter"><CountUp end={36} /></span>
												<small>k</small>
											</div>
											<span className="counter-text">Happy Clients</span>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30">
										<div className="counter-style-1 text-center">
											<div className="counter-num text-primary">
												<span className="counter"><CountUp end={99} /></span>
											</div>
											<span className="counter-text">Perfect Products</span>
										</div>
									</div>
								</div>
							</div>
							<div className="container">
								<div className="row m-lr0 about-area1">
									<div className="col-lg-6 p-lr0">
										<img className="img-cover" src={require('./../../images/about/pic3.webp')} alt="" />
									</div>
									<div className="col-lg-6 p-lr0 d-flex align-items-center text-center">
										<div className="about-bx">
											<div className="section-head text-center text-white">
												<h4 className="text-white">20% Off</h4>
												<p>Birthday on Yachts !</p>
												<div className="icon-bx">
													<img src={require('./../../images/icons/service-icon/icon2.png')} alt="" />
												</div>
											</div>
											<p>including Cake,cold drinks,decoration and fishing on demand</p>
											<Link to={'/contact'} className="btn-secondry white btnhover btn-md"><i className="fa fa-cart"></i>GET NOW</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
		)
	}
}

export default Index1;