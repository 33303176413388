import React from 'react';
import { ourYachtsData } from "../data";
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import Card from 'react-bootstrap/Card';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";

var bnr = require('./../../images/banner/bnr1.webp');

function Shop () {
	const history = useHistory();
	const yachts= ourYachtsData.filter(ourYachtData => ourYachtData.id <= 34);
	
	
		return (
			<div>
			<Helmet>
        <title>Habibi Yachts | Yachts</title>
		<link rel="canonical" href="https://www.habibiyachts.com/yachts" />
        <meta name="keywords" content="habibi Yachts, habibi Yachts Dubai,habibi.com,habibiyachts.com,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts href rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht href rent dubai,
yachts dubai rent" />
    </Helmet>
				<Header />

				<div className="page-content bg-white">

					<div className="dlab-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<div className="container">
							<div className="dlab-bnr-inr-entry">
								<h1 className="text-white">Yachts</h1>

								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><a href={'./'}><i className="fa fa-home"></i></a></li>
										<li>Our Yachts</li>
									</ul>
								</div>

							</div>
						</div>
					</div>

					<div className="content-block">

						<div className="section-full content-inner bg-gray-light">
							<div className="container">
								<div className="row">{
								yachts.map(ourYachtData =>
											<div className="col-lg-3 col-md-6 col-sm-6">
												<div className="item-box shop-item">
													<Card style={{marginhrefp: '20px',marginTop:'20px'}} onClick={() => history.push(`/detail/${ourYachtData.id}`)}>
														<Card.Img className="item-img" variant="hrefp"  src={ourYachtData.image} alt=""  />
														<Card.Body >
															<Card.Text className="item-info text-center">
															<h4 className="item-title">{ourYachtData.title}</h4>
															</Card.Text>
														</Card.Body>
													</Card>
												</div>
											</div>
								)}									
								</div>
							</div>
						</div>

					</div>
				</div>

				<Footer />

			</div>

		)
}

export default Shop;