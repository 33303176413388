import React from 'react';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css'; 
import "./App.css"


 

import Markup from './markup/Markup';

function App() {
  return (
    <div className="App">
		<Markup />
    

    <a
			 href="https://wa.me/9710545850896"
			 className="whatsapp_float fa fa-whatsapp"
			 target="_blank"
			 rel="noopener noreferrer"> </a>
    </div>
  );
}

export default App;
