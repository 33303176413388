// Our Services Packages
export const gifts = [
    {
        id: 35,
        image: '/images/gifts/surprise box/1.webp',
        images:[
            '/images/gifts/surprise box/1.webp',
            '/images/gifts/surprise box/2.webp',
            '/images/gifts/surprise box/3.webp', 
        ],
        title: 'Surprise Box',
        yachtLength: 'Gift',
        capacity: 'Surprise Box',
        feature1: 'Balloons',
        price:' AED 280',
        inclusions:'1 transparent balloon with message | helium balloons | 10 other balloon | Msg on box  | cake 🎂 | rose petals | pics inside | LED lights'

    },
    {
        id: 36,
        image: '/images/gifts/cake box/1.webp',
        images:[
            '/images/gifts/cake box/1.webp',
            '/images/gifts/cake box/2.webp',
        ],
        title: 'Special Cake box',
        yachtLength: 'celebrations',
        capacity: 'cake',
        feature1: 'Balloons',
        price:' AED 149',
        inclusions:'Fresh cake | Transparent box | Customise message on cake | Any flavor of cake | Rose petals inside box | Message letter'

    },
]

// our yachts data 
export const ourYachtsData = [
   
     {
        id: 3,
        image: '/images/Yachts/42FT RBC/1.webp',
        images:[
            '/images/Yachts/42FT RBC/1.webp',
            '/images/Yachts/42FT RBC/2.webp',
            '/images/Yachts/42FT RBC/3.webp',
            '/images/Yachts/42FT RBC/4.webp',
            '/images/Yachts/42FT RBC/5.webp',
            '/images/Yachts/42FT RBC/6.webp',
            '/images/Yachts/42FT RBC/7.webp',
            '/images/Yachts/42FT RBC/8.webp',
            '/images/Yachts/42FT RBC/9.webp',
            '/images/Yachts/42FT RBC/10.webp',
            '/images/Yachts/42FT RBC/11.webp',
            '/images/Yachts/42FT RBC/12.webp',
            '/images/Yachts/42FT RBC/13.webp',
            '/images/Yachts/42FT RBC/14.webp',
        ],
        title: 'Half Moon',
        yachtLength: '42 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 4,
        image: '/images/Yachts/48FT RBC/1.webp',
        images:[
            '/images/Yachts/48FT RBC/1.webp',
            '/images/Yachts/48FT RBC/2.webp',
            '/images/Yachts/48FT RBC/3.webp',
            '/images/Yachts/48FT RBC/4.webp',
            '/images/Yachts/48FT RBC/5.webp',
            '/images/Yachts/48FT RBC/6.webp',
            '/images/Yachts/48FT RBC/7.webp',
            '/images/Yachts/48FT RBC/8.webp',
            '/images/Yachts/48FT RBC/9.webp',
            '/images/Yachts/48FT RBC/10.webp',
            '/images/Yachts/48FT RBC/11.webp',
            '/images/Yachts/48FT RBC/12.webp',
        ],
        title: 'Blue Star',
        yachtLength: '48 FT',
        capacity: '11 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'


    },
    {
        id: 5,
        image: '/images/Yachts/50FT RBC/1.webp',
        images:[
            '/images/Yachts/50FT RBC/1.webp',
            '/images/Yachts/50FT RBC/2.webp',
            '/images/Yachts/50FT RBC/3.webp',
            '/images/Yachts/50FT RBC/4.webp',
            '/images/Yachts/50FT RBC/5.webp',
            '/images/Yachts/50FT RBC/6.webp',
            '/images/Yachts/50FT RBC/7.webp',
            '/images/Yachts/50FT RBC/8.webp',
            '/images/Yachts/50FT RBC/9.webp',
            '/images/Yachts/50FT RBC/10.webp',
            '/images/Yachts/50FT RBC/11.webp',
            '/images/Yachts/50FT RBC/12.webp',
            '/images/Yachts/50FT RBC/13.webp',
            '/images/Yachts/50FT RBC/14.webp',
            '/images/Yachts/50FT RBC/15.webp',
        ],
        title: 'White Magic',
        yachtLength: '50 FT',
        capacity: '13 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    }, 
    {
        id: 7,
        image: '/images/Yachts/56ft RBC/1.webp',
        images:[
            '/images/Yachts/56ft RBC/1.webp',
            '/images/Yachts/56ft RBC/2.webp',
            '/images/Yachts/56ft RBC/3.webp',
            '/images/Yachts/56ft RBC/4.webp',
            '/images/Yachts/56ft RBC/5.webp',
            '/images/Yachts/56ft RBC/6.webp',
            '/images/Yachts/56ft RBC/7.webp',
            '/images/Yachts/56ft RBC/8.webp',
            '/images/Yachts/56ft RBC/9.webp',
            '/images/Yachts/56ft RBC/10.webp',
            '/images/Yachts/56ft RBC/11.webp',
            '/images/Yachts/56ft RBC/12.webp',
        ],
        title: 'Final Destination',
        yachtLength: '56 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 8,
        image: '/images/Yachts/45ft mighty mnh/1.webp',
        images:[
            '/images/Yachts/45ft mighty mnh/1.webp',
            '/images/Yachts/45ft mighty mnh/2.webp',
            '/images/Yachts/45ft mighty mnh/3.webp',
            '/images/Yachts/45ft mighty mnh/4.webp',
            '/images/Yachts/45ft mighty mnh/5.webp',
            '/images/Yachts/45ft mighty mnh/6.webp',
        ],
        title: 'Mighty',
        yachtLength: '45 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 9,
        image: '/images/Yachts/65ft seaduction mnh/1.webp',
        images:[
            '/images/Yachts/65ft seaduction mnh/1.webp',
            '/images/Yachts/65ft seaduction mnh/2.webp',
            '/images/Yachts/65ft seaduction mnh/3.webp',
            '/images/Yachts/65ft seaduction mnh/4.webp',
            '/images/Yachts/65ft seaduction mnh/5.webp',
        ],
        title: 'Seaduction',
        yachtLength: '65 FT',
        capacity: '20 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 10,
        image: '/images/Yachts/75ft grace mnh/1.webp',
        images:[
            '/images/Yachts/75ft grace mnh/1.webp',
            '/images/Yachts/75ft grace mnh/2.webp',
            '/images/Yachts/75ft grace mnh/3.webp',
            '/images/Yachts/75ft grace mnh/4.webp',
            '/images/Yachts/75ft grace mnh/5.webp',
            '/images/Yachts/75ft grace mnh/6.webp',
            '/images/Yachts/75ft grace mnh/7.webp',
            '/images/Yachts/75ft grace mnh/8.webp',
            '/images/Yachts/75ft grace mnh/9.webp',
            '/images/Yachts/75ft grace mnh/10.webp',
            '/images/Yachts/75ft grace mnh/11.webp',
        ],
        title: 'Grace',
        yachtLength: '75 FT',
        capacity: '25 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 11,
        image: '/images/Yachts/alshali royal yacht 50ft/1.webp',
        images:[
            '/images/Yachts/alshali royal yacht 50ft/1.webp',
            '/images/Yachts/alshali royal yacht 50ft/2.webp',
            '/images/Yachts/alshali royal yacht 50ft/3.webp',
            '/images/Yachts/alshali royal yacht 50ft/4.webp',
            '/images/Yachts/alshali royal yacht 50ft/5.webp',
            '/images/Yachts/alshali royal yacht 50ft/6.webp',
            '/images/Yachts/alshali royal yacht 50ft/7.webp',
            '/images/Yachts/alshali royal yacht 50ft/8.webp',
        ],
        title: 'Alshali',
        yachtLength: '50 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 12,
        image: '/images/Yachts/alshali sport 50ft ruyal/1.webp',
        images:[
            '/images/Yachts/alshali sport 50ft ruyal/1.webp',
            '/images/Yachts/alshali sport 50ft ruyal/2.webp',
            '/images/Yachts/alshali sport 50ft ruyal/3.webp',
            '/images/Yachts/alshali sport 50ft ruyal/4.webp',
            '/images/Yachts/alshali sport 50ft ruyal/5.webp',
            '/images/Yachts/alshali sport 50ft ruyal/6.webp',
            '/images/Yachts/alshali sport 50ft ruyal/7.webp',
            '/images/Yachts/alshali sport 50ft ruyal/8.webp',
        ],
        title: 'Alshali Sport',
        yachtLength: '50 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 13,
        image: '/images/Yachts/arabian pearl/1.webp',
        images:[
            '/images/Yachts/arabian pearl/1.webp',
            '/images/Yachts/arabian pearl/2.webp',
            '/images/Yachts/arabian pearl/3.webp',
            '/images/Yachts/arabian pearl/4.webp',
            '/images/Yachts/arabian pearl/5.webp',
        ],
        title: 'Arabian Pearl',
        yachtLength: '90 FT',
        capacity: '45 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 14,
        image: '/images/Yachts/as marine faisaal silfar/1.webp',
        images:[
            '/images/Yachts/as marine faisaal silfar/1.webp',
            '/images/Yachts/as marine faisaal silfar/2.webp',
            '/images/Yachts/as marine faisaal silfar/3.webp',
            '/images/Yachts/as marine faisaal silfar/4.webp',
            '/images/Yachts/as marine faisaal silfar/5.webp',
            '/images/Yachts/as marine faisaal silfar/6.webp',
            '/images/Yachts/as marine faisaal silfar/7.webp',
            '/images/Yachts/as marine faisaal silfar/8.webp',
            '/images/Yachts/as marine faisaal silfar/9.webp',
            '/images/Yachts/as marine faisaal silfar/10.webp',
        ],
        title: 'As Marine',
        yachtLength: '70 FT',
        capacity: '20 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 15,
        image: '/images/Yachts/azzimut 52ft royal/1.webp',
        images:[
            '/images/Yachts/azzimut 52ft royal/1.webp',
            '/images/Yachts/azzimut 52ft royal/2.webp',
            '/images/Yachts/azzimut 52ft royal/3.webp',
            '/images/Yachts/azzimut 52ft royal/4.webp',
            '/images/Yachts/azzimut 52ft royal/5.webp',
            '/images/Yachts/azzimut 52ft royal/6.webp',
            '/images/Yachts/azzimut 52ft royal/7.webp',
            '/images/Yachts/azzimut 52ft royal/8.webp',
            '/images/Yachts/azzimut 52ft royal/9.webp',
        ],
        title: 'Azzimut',
        yachtLength: '52 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 16,
        image: '/images/Yachts/b ricon 68ft  royal yachts/1.webp',
        images:[
            '/images/Yachts/b ricon 68ft  royal yachts/1.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/2.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/3.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/4.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/5.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/6.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/7.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/8.webp',
            '/images/Yachts/b ricon 68ft  royal yachts/9.webp',
        ],
        title: 'B Ricon',
        yachtLength: '68 FT',
        capacity: '20 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 17,
        image: '/images/Yachts/big daddy 95ft royal yachts/1.webp',
        images:[
            '/images/Yachts/big daddy 95ft royal yachts/1.webp',
            '/images/Yachts/big daddy 95ft royal yachts/2.webp',
            '/images/Yachts/big daddy 95ft royal yachts/3.webp',
            '/images/Yachts/big daddy 95ft royal yachts/4.webp',
            '/images/Yachts/big daddy 95ft royal yachts/5.webp',
            '/images/Yachts/big daddy 95ft royal yachts/6.webp',
            '/images/Yachts/big daddy 95ft royal yachts/7.webp',
            '/images/Yachts/big daddy 95ft royal yachts/8.webp',
            '/images/Yachts/big daddy 95ft royal yachts/9.webp',
            '/images/Yachts/big daddy 95ft royal yachts/10.webp',
            '/images/Yachts/big daddy 95ft royal yachts/11.webp',
        ],
        title: 'Big Daddy',
        yachtLength: '95 FT',
        capacity: '45 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 18,
        image: '/images/Yachts/black pearl 90ft royal yachts/1.webp',
        images:[
            '/images/Yachts/black pearl 90ft royal yachts/1.webp',
            '/images/Yachts/black pearl 90ft royal yachts/2.webp',
            '/images/Yachts/black pearl 90ft royal yachts/3.webp',
            '/images/Yachts/black pearl 90ft royal yachts/4.webp',
            '/images/Yachts/black pearl 90ft royal yachts/5.webp',
            '/images/Yachts/black pearl 90ft royal yachts/6.webp',
            '/images/Yachts/black pearl 90ft royal yachts/7.webp',
            '/images/Yachts/black pearl 90ft royal yachts/8.webp',
            '/images/Yachts/black pearl 90ft royal yachts/9.webp',
            '/images/Yachts/black pearl 90ft royal yachts/10.webp',
            '/images/Yachts/black pearl 90ft royal yachts/11.webp',
            '/images/Yachts/black pearl 90ft royal yachts/12.webp',
        ],
        title: 'Black Pearl',
        yachtLength: '90 FT',
        capacity: '40 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 19,
        image: '/images/Yachts/blue moon 55ft mnh/1.webp',
        images:[
            '/images/Yachts/blue moon 55ft mnh/1.webp',
            '/images/Yachts/blue moon 55ft mnh/2.webp',
            '/images/Yachts/blue moon 55ft mnh/3.webp',
            '/images/Yachts/blue moon 55ft mnh/4.webp',
            '/images/Yachts/blue moon 55ft mnh/5.webp',
            '/images/Yachts/blue moon 55ft mnh/6.webp',
            '/images/Yachts/blue moon 55ft mnh/7.webp',
            '/images/Yachts/blue moon 55ft mnh/8.webp',
        ],
        title: 'Blue Moon',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 20,
        image: '/images/Yachts/conway alshali 55ft/1.webp',
        images:[
            '/images/Yachts/conway alshali 55ft/1.webp',
            '/images/Yachts/conway alshali 55ft/2.webp',
            '/images/Yachts/conway alshali 55ft/3.webp',
            '/images/Yachts/conway alshali 55ft/4.webp',
            '/images/Yachts/conway alshali 55ft/5.webp',
            '/images/Yachts/conway alshali 55ft/6.webp',
        ],
        title: 'C Alshali',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 21,
        image: '/images/Yachts/conwy craft 34ft/1.webp',
        images:[
            '/images/Yachts/conwy craft 34ft/1.webp',
            '/images/Yachts/conwy craft 34ft/2.webp',
            '/images/Yachts/conwy craft 34ft/3.webp',
            
        ],
        title: 'C Craft',
        yachtLength: '34 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 22,
        image: '/images/Yachts/conwy silver craft 27ft/1.webp',
        images:[
            '/images/Yachts/conwy silver craft 27ft/1.webp',
            '/images/Yachts/conwy silver craft 27ft/2.webp',
            '/images/Yachts/conwy silver craft 27ft/3.webp',
            '/images/Yachts/conwy silver craft 27ft/4.webp',
            '/images/Yachts/conwy silver craft 27ft/5.webp',
        ],
        title: 'C Silver Craft',
        yachtLength: '27 FT',
        capacity: '6 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 24,
        image: '/images/Yachts/dessert rose/1.webp',
        images:[
            '/images/Yachts/dessert rose/1.webp',
            '/images/Yachts/dessert rose/2.webp',
            '/images/Yachts/dessert rose/3.webp',
            '/images/Yachts/dessert rose/4.webp',
            '/images/Yachts/dessert rose/5.webp',
            '/images/Yachts/dessert rose/6.webp',
            '/images/Yachts/dessert rose/7.webp',
            '/images/Yachts/dessert rose/8.webp',
        ],
        title: 'Dessert Rose',
        yachtLength: '155 FT',
        capacity: '250 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 25,
        image: '/images/Yachts/gugu boat/1.webp',
        images:[
            '/images/Yachts/gugu boat/1.webp',
            '/images/Yachts/gugu boat/2.webp',
            '/images/Yachts/gugu boat/3.webp',
            '/images/Yachts/gugu boat/4.webp',
            '/images/Yachts/gugu boat/5.webp',
            '/images/Yachts/gugu boat/6.webp',
            '/images/Yachts/gugu boat/7.webp',
            '/images/Yachts/gugu boat/8.webp',
            '/images/Yachts/gugu boat/9.webp',
            '/images/Yachts/gugu boat/10.webp',
        ],
        title: 'GuGu Boat',
        yachtLength: '110 FT',
        capacity: '80 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 26,
        image: '/images/Yachts/gulf craft 55ft royal/1.webp',
        images:[
            '/images/Yachts/gulf craft 55ft royal/1.webp',
            '/images/Yachts/gulf craft 55ft royal/2.webp',
            '/images/Yachts/gulf craft 55ft royal/3.webp',
            '/images/Yachts/gulf craft 55ft royal/4.webp',
            '/images/Yachts/gulf craft 55ft royal/5.webp',
            '/images/Yachts/gulf craft 55ft royal/6.webp',
        ],
        title: 'Gulf Craft',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 27,
        image: '/images/Yachts/jasmine 55ft royal/1.webp',
        images:[
            '/images/Yachts/jasmine 55ft royal/1.webp',
            '/images/Yachts/jasmine 55ft royal/2.webp',
            '/images/Yachts/jasmine 55ft royal/3.webp',
            '/images/Yachts/jasmine 55ft royal/4.webp',
            '/images/Yachts/jasmine 55ft royal/5.webp',
            '/images/Yachts/jasmine 55ft royal/6.webp',
            '/images/Yachts/jasmine 55ft royal/7.webp',
            '/images/Yachts/jasmine 55ft royal/8.webp',
            '/images/Yachts/jasmine 55ft royal/9.webp',
            '/images/Yachts/jasmine 55ft royal/10.webp',
            '/images/Yachts/jasmine 55ft royal/11.webp',
        ],
        title: 'Jasmine',
        yachtLength: '55 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 28,
        image: '/images/Yachts/legacy mnh 34ft/1.webp',
        images:[
            '/images/Yachts/legacy mnh 34ft/1.webp',
            '/images/Yachts/legacy mnh 34ft/2.webp',
            '/images/Yachts/legacy mnh 34ft/3.webp',
            '/images/Yachts/legacy mnh 34ft/4.webp',
            '/images/Yachts/legacy mnh 34ft/5.webp',
            '/images/Yachts/legacy mnh 34ft/6.webp',
        ],
        title: 'Legacy',
        yachtLength: '34 FT',
        capacity: '10 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 29,
        image: '/images/Yachts/lotus/1.webp',
        images:[
            '/images/Yachts/lotus/1.webp',
            '/images/Yachts/lotus/2.webp',
            '/images/Yachts/lotus/3.webp',
            '/images/Yachts/lotus/4.webp',
            '/images/Yachts/lotus/5.webp',
            '/images/Yachts/lotus/6.webp',
            '/images/Yachts/lotus/7.webp',
            '/images/Yachts/lotus/8.webp',
            '/images/Yachts/lotus/9.webp',
            '/images/Yachts/lotus/10.webp',
            '/images/Yachts/lotus/11.webp',
        ],
        title: 'Lotus',
        yachtLength: '240 FT',
        capacity: '500 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 30,
        image: '/images/Yachts/majesty 56ft royal/1.webp',
        images:[
            '/images/Yachts/majesty 56ft royal/1.webp',
            '/images/Yachts/majesty 56ft royal/2.webp',
            '/images/Yachts/majesty 56ft royal/3.webp',
            '/images/Yachts/majesty 56ft royal/4.webp',
            '/images/Yachts/majesty 56ft royal/5.webp',
            '/images/Yachts/majesty 56ft royal/6.webp',
            '/images/Yachts/majesty 56ft royal/7.webp',
            '/images/Yachts/majesty 56ft royal/8.webp',
        ],
        title: 'Majesty',
        yachtLength: '56 FT',
        capacity: '16 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 31,
        image: '/images/Yachts/monalisa/1.webp',
        images:[
            '/images/Yachts/monalisa/1.webp',
            '/images/Yachts/monalisa/2.webp',
            '/images/Yachts/monalisa/3.webp',
            '/images/Yachts/monalisa/4.webp',
            '/images/Yachts/monalisa/5.webp',
            '/images/Yachts/monalisa/6.webp',
            '/images/Yachts/monalisa/7.webp',
            '/images/Yachts/monalisa/8.webp',
            '/images/Yachts/monalisa/9.webp',
            '/images/Yachts/monalisa/10.webp',
            '/images/Yachts/monalisa/11.webp',
            '/images/Yachts/monalisa/12.webp',
            '/images/Yachts/monalisa/13.webp',
            '/images/Yachts/monalisa/14.webp',
        ],
        title: 'Monalisa',
        yachtLength: '150 FT',
        capacity: '95 PAX',
        feature1: 'Captain & 2 Crew',
        feature2: 'xyz',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 32,
        image: '/images/Yachts/ocean pearl/1.webp',
        images:[
            '/images/Yachts/ocean pearl/1.webp',
            '/images/Yachts/ocean pearl/2.webp',
            '/images/Yachts/ocean pearl/3.webp',
            '/images/Yachts/ocean pearl/4.webp',
            '/images/Yachts/ocean pearl/5.webp',
            '/images/Yachts/ocean pearl/6.webp',
            '/images/Yachts/ocean pearl/7.webp',
        ],
        title: 'Ocean Pearl',
        yachtLength: '85 FT',
        capacity: '45 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 33,
        image: '/images/Yachts/ricon 64ft royal/1.webp',
        images:[
            '/images/Yachts/ricon 64ft royal/1.webp',
            '/images/Yachts/ricon 64ft royal/2.webp',
            '/images/Yachts/ricon 64ft royal/3.webp',
            '/images/Yachts/ricon 64ft royal/4.webp',
            '/images/Yachts/ricon 64ft royal/5.webp',
            '/images/Yachts/ricon 64ft royal/6.webp',
            '/images/Yachts/ricon 64ft royal/7.webp',
        ],
        title: 'Ricon',
        yachtLength: '64 FT',
        capacity: '15 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 34,
        image: '/images/Yachts/virgo/1.webp',
        images:[
            '/images/Yachts/virgo/1.webp',
            '/images/Yachts/virgo/2.webp',
            '/images/Yachts/virgo/3.webp',
            '/images/Yachts/virgo/4.webp',
            '/images/Yachts/virgo/5.webp',
            '/images/Yachts/virgo/6.webp',
            '/images/Yachts/virgo/7.webp',
            '/images/Yachts/virgo/8.webp',
            '/images/Yachts/virgo/9.webp',
            '/images/Yachts/virgo/10.webp',
            '/images/Yachts/virgo/11.webp',
            '/images/Yachts/virgo/12.webp',
            '/images/Yachts/virgo/13.webp',
            '/images/Yachts/virgo/14.webp',
            '/images/Yachts/virgo/15.webp',
            '/images/Yachts/virgo/16.webp',
            '/images/Yachts/virgo/17.webp',
        ],
        title: 'virgo',
        yachtLength: '85 FT',
        capacity: '40 PAX',
        feature1: 'Captain & 2 Crew',
        price:'',
        inclusions:'Free Soft Drinks | Water & Ice | Swimming Fishing Equipment (Additional charges) | BBQ Grill (Additional charges) | Life Jackets'

    },
    {
        id: 35,
        image: '/images/gifts/surprise box/1.webp',
        images:[
            '/images/gifts/surprise box/1.webp',
            '/images/gifts/surprise box/2.webp',
            '/images/gifts/surprise box/3.webp', 
        ],
        title: 'Surprise Box',
        yachtLength: 'Gift',
        capacity: 'Surprise Box',
        feature1: 'Balloons',
        price:' AED 280',
        inclusions:'1 transparent balloon with message | helium balloons | 10 other balloon | Msg on box  | cake 🎂 | rose petals | pics inside | LED lights'

    },
    {
        id: 36,
        image: '/images/gifts/cake box/1.webp',
        images:[
            '/images/gifts/cake box/1.webp',
            '/images/gifts/cake box/2.webp', 
        ],
        title: 'Special Cake box',
        yachtLength: 'celebrations',
        capacity: 'cake',
        feature1: 'Balloons',
        price:' AED 150',
        inclusions:'Fresh cake | Transparent box | Customise message on cake | Any flavor of cake | Rose petals inside box | Message letter'

    },
    {
        id: 37,
        image: '/images/gifts/Cake box and ballons/1.webp',
        images:[
            '/images/gifts/Cake box and ballons/1.webp',
            '/images/gifts/Cake box and ballons/2.webp',
            '/images/gifts/Cake box and ballons/3.webp', 
        ],
        title: 'Cake box and ballons',
        yachtLength: 'celebrations',
        capacity: 'cake',
        feature1: 'Balloons',
        price:' AED 180',
        inclusions:'Fresh cake| Transparent box | Helium balloons | Any colour of balloons | Rose petals inside box | Transparent balloon with message | Any flavor of cake |Fresh rose 🌹 | Customise message on cake'

    },
    {
        id: 38,
        image: '/images/gifts/Balloon bouquet/1.webp',
        images:[
            '/images/gifts/Balloon bouquet/1.webp',
            '/images/gifts/Balloon bouquet/2.webp',
            '/images/gifts/Balloon bouquet/3.webp', 
        ],
        title: 'Balloon bouquet',
        yachtLength: 'celebrations',
        capacity: 'Banquet',
        feature1: 'Balloons',
        price:' AED 199',
        inclusions:'chocolates | Any colour of balloons | Transparent balloon with message | Any colour theme |Fresh rose 🌹 | Message card'

    },
    {
        id: 39,
        image: '/images/gifts/Valentines Special/1.webp',
        images:[
            '/images/gifts/Valentines Special/1.webp',
            '/images/gifts/Valentines Special/2.webp', 
        ],
        title: 'Valentines Special',
        yachtLength: 'celebrations',
        capacity: 'Banquet',
        feature1: 'Balloons',
        price:' AED 180',
        inclusions:'3 pcs forrero | Teddy bear | Any colour theme |Fresh rose 🌹 | Any msg on balloon'

    },
    {
        id: 40,
        image: '/images/gifts/Flower Bouquet/1.webp',
        images:[
            '/images/gifts/Flower Bouquet/1.webp',
            '/images/gifts/Flower Bouquet/2.webp',
        ],
        title: 'Flower Bouquet',
        yachtLength: 'celebrations',
        capacity: 'wrap',
        feature1: 'roses',
        price:' AED 150',
        inclusions:'Fresh roses | Message card | Any colour of roses | Any wrap colour'

    },
    {
        id: 41,
        image: '/images/gifts/Money bouquet/2.webp',
        images:[
            '/images/gifts/Money bouquet/1.webp',
            '/images/gifts/Money bouquet/2.webp',
        ],
        title: 'Money bouquet',
        yachtLength: 'celebrations',
        capacity: 'wrap',
        feature1: 'roses',
        price:' AED 150',
        inclusions:'Fresh roses | Message card | Any colour of roses | Any wrap colour'

    },
    {
        id: 42,
        image: '/images/gifts/Customise Mug/1.webp',
        images:[
            '/images/gifts/Customise Mug/1.webp',
            '/images/gifts/Customise Mug/2.webp',
        ],
        title: 'Customise Mug',
        yachtLength: 'gift',
        capacity: 'mug',
        feature1: 'cup',
        price:' AED 80',
        inclusions:'Customise Mug | name on mug | Any text of your choice | lable on mug | image on Mug | Logo on mug'

    },
    {
        id: 43,
        image: '/images/gifts/Customise pillow/1.webp',
        images:[
            '/images/gifts/Customise pillow/1.webp',
            '/images/gifts/Customise pillow/2.webp',
        ],
        title: 'Customise Pillow',
        yachtLength: 'gift',
        capacity: 'Pillow',
        feature1: 'Special',
        price:' AED 99',
        inclusions:'Customise Pillow | name on Pillow | Any text of your choice | Image on Pillow | Logo on Pillow'

    },
    {
        id: 44,
        image: '/images/gifts/Magic pillow/1.webp',
        images:[
            '/images/gifts/Magic pillow/1.webp',
            '/images/gifts/Magic pillow/2.webp',
        ],
        title: 'Magic pillow',
        yachtLength: 'gift',
        capacity: 'magic',
        feature1: 'pillow',
        price:' AED 99',
        inclusions:'Customise pillow | name on pillow | Any text of your choice | Image on pillow'

    },
    
]
    
// master detail flow function of detail page
export const getDataById = (id) => {
    return ourYachtsData.find(finding => finding.id === Number(id));
}


