import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

class Accord extends Component{
	render(){		
		return(
			<div className="dlab-accordion faq-style1">
				<Accordion defaultActiveKey="0">		
					<Accordion.Toggle className="panel" as={'div'}  eventKey="0">
						<div className="acod-head">
							<h6 className="acod-title"> 
								<Link to = {''} data-toggle="collapse" data-target="#collapse1" aria-expanded="true"> 
								<i className="fa fa-cart-plus"></i> Where We Provide Our Services?</Link> 
							</h6>
						</div>
						<Accordion.Collapse eventKey="0">
							<div id="collapse1" className="acod-body collapse show" data-parent="#accordion001">
								<div className="acod-content">
									<img src={require('./../../images/map.png')}  alt=" United Arab Emirates map" />
									<ul className="list-check mb-0 primary">
										<li>Abu Dhabi</li>
										<li>Dubai</li>
										<li>Sharjah</li>
										<li>Ajman</li>
										<li>Umm Al Quwain</li>
										<li>Ras Al Khaimah</li>
										<li>Fujairah</li>
									</ul>
								</div>
							</div>	
						</Accordion.Collapse>
					</Accordion.Toggle>	
						
				</Accordion>
			</div>	
		)
	}

}

export default Accord;