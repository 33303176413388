import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index1 from './Pages/Index1';
import Ourservices from './Pages/Ourservices';
import Ourmenustyle1 from './Pages/Ourmenustyle1';
import Shopproduct from './Pages/Shopproduct';
import Shop from './Pages/Shop';
import Contact from './Pages/Contact';



class Markup extends Component{
	
	render(){
		return(

		
			<BrowserRouter>
				<Switch>
					<Route path='/' exact component={Index1} />

					<Route path='/decorations' exact component ={Ourservices} />
					
					<Route path='/gifts' exact component ={Ourmenustyle1} />
					
					<Route path='/yachts' exact component ={Shop} />
					
					<Route path='/contact' exact component ={Contact} />

					<Route exact path='/detail/:id' component={Shopproduct} />
				
					
				</Switch>
			</ BrowserRouter>
		)
		
	}
}

export default Markup;