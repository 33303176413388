import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import {Carousel} from 'react-bootstrap';
import slider1 from './../../images/main-slider/slide1.webp';
import slider2 from './../../images/main-slider/slide2.webp';
import slider3 from './../../images/main-slider/slide3.webp';

class Slider extends Component{
	render(){
		return(
			<div className="main-slider">
				<Carousel indicators={false}>
					<Carousel.Item>
						<div className="slide" style={{ backgroundImage: "url("+ slider1 +")"}} >
							{/* <img className="d-block w-100 slider" src={require('./../../images/main-slider/slide1.jpg')}	alt="Second slide"	/> */}
							<div className="content">
								<span>Yachts & Events</span>
								<h2 className="title">Welcome To Habibi Yachts & Events</h2>
								<h4 className="sub-title">The Best Yachts Rental Company In UAE</h4>
								<Link to={"/contact"} className="btn btnhover">Contact Us</Link>
							</div>	
						</div>	
					</Carousel.Item>
					<Carousel.Item>
						<div className="slide" style={{ backgroundImage: "url("+ slider2 +")"}} >
							{/* <img className="d-block w-100 slider"	src={require('./../../images/main-slider/slide2.jpg')}	alt="Second slide"	/> */}
							<div className="content">
								<span>Yachts & Events</span>
								<h2 className="title">Occasion Gifts</h2>
								<h4 className="sub-title">The Best Online Gift Shop In UAE</h4>
								<Link to={"/contact"} className="btn btnhover">Contact Us</Link>
							</div>	
						</div>	
					</Carousel.Item>
					<Carousel.Item>
						<div className="slide" style={{ backgroundImage: "url("+ slider3 +")"}} >
							{/* <img className="d-block w-100 slider"	src={require('./../../images/main-slider/slide2.jpg')}	alt="Second slide"	/> */}
							<div className="content">
								<span>Yachts & Events</span>
								<h2 className="title">BirthDays</h2>
								<h4 className="sub-title">The Best Birthday celebrations on Yachts In UAE</h4>
								<Link to={"/contact"} className="btn btnhover">Contact Us</Link>
							</div>	
						</div>	
					</Carousel.Item>
				</Carousel>
			</div>
		)
	}	
}

export default Slider;