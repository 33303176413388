import React, { Component } from 'react';
import { Link } from 'react-router-dom';

var bgfoter = require('./../../images/background/bg2.webp');	


class Footer extends Component{
	
	render(){
		return(
			<footer className="site-footer " style={{backgroundImage: "url(" + bgfoter + ")", backgroundSize: 'cover'}} >
				
				<div className="footer-top bg-line-top">
					<div className="container">
						<div className="row">
							<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
								<div className="widget widget_getintuch">
									<h5 className="footer-title text-white">Contact Us</h5>
									<ul>
										<li>
											<i className="fa fa-map-marker"></i> 
											<p>Dubai Marina, UAE</p>
										</li>
										<li>
										<a href={'tel:+9710545850896'} className="icon-cell"><i className="fa fa-phone"></i> </a>
											<p>054 585 0896</p>
										</li>
										<li>
										<a href={'https://wa.me/9710545850896'} target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp"></i></a> 
											<p>(+971) 054 585 0896</p>
										</li>
										<li>
										<a href={"mailto:hababiyachts@gmail.com"} target="_blank" rel="noopener noreferrer"><i className="fa fa-envelope"></i></a>
											<p>hababiyachts@gmail.com</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
								<div className="widget widget_services border-0">
								   <h4 className="footer-title">Quick Links</h4>
									<ul className="list-2">
										<li><Link to={'/'}>Home</Link></li>
										<li><Link to={'/Gifts'}>Our Gifts</Link></li>
										<li><Link to={'/yachts'}>Yachts</Link></li>
										<li><Link to={'/contact'}>Contact</Link></li>
										<li><Link to={'/our-services'}>Our Services</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
								<div className="widget border-0">
								   <h4 className="footer-title">Opening Hours</h4>
								   <p className="m-b20">Our support available to help you 24 hours a day, seven days a week.</p>
									<ul className="work-hour-list">
										<li>
											<span className="day"><span>Monday to Sunday</span></span> 
											<span className="timing">10AM - 11PM</span>
										</li>
										<li>
											<span className="day"><span>Friday</span></span>
											<span className="timing">2PM - 12AM</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 text-left"> <a href={'https://khubair.netlify.app'} target="_blank" rel="noopener noreferrer"> <span>Copyright © 2022 Khubair Ali. all rights reserved.</span></a>  </div>
							<div className="col-lg-6 col-md-6 text-right"> 
								<div className="widget-link"> 
									<ul>
										<li><a href={'https://khubair.netlify.app'} target="_blank" rel="noopener noreferrer">Contact Developer</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			
		)
		
	}
}

export default Footer;