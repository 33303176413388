import React from 'react';
import {Link,useHistory} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import Card from 'react-bootstrap/Card';
import { ourYachtsData } from "../data";
import {Helmet} from "react-helmet";

var img1 = require('./../../images/banner/bnr2.webp');
var img3 = require('./../../images/banner/bnr2.webp');
var img4 = require('./../../images/background/bg1.webp');



function Ourmenustyle1() {
	const history = useHistory();
	const gifts= ourYachtsData.filter(ourYachtData => ourYachtData.id > 34);
		return(
			<>
			<Helmet>
					<title>Habibi Yachts | Gifts</title>
					<link rel="canonical" href="https://www.habibiyachts.com/gifts" />
					<meta name="description" content="Habibi Yachts are not just a yacht rental company, we are an extraordinary luxury event & luxury yacht rental planning company with a complete package of moments and celebrations under one platform. We have UAE's best Yachts Charter services with  Sunseeker & Benetti Yachts. Jetski & Seabob. " />
					<meta name="keywords" content="habibi Yachts and events,habibi gifts,habibi gift shop,habibiyachts.com,Our Gifts and Surprises,our gifts,surprises,dubai flowers,flowers,bouquet of flowers,flowers bouquet,boys over flowers,flowers bouquet,gift boxes dubai,gift box uae,gift box for men,
gift box abu dhabi,gift box dubai,gift box ajman,gift box birthday,gift box cake,cakes near me,
cake shop,cake abu dhabi,cake and flower delivery,cake box,cake birthday,cake boss,cake delivery in dubai,cake dubai,cake delivery abu dhabi,
cake flavours,cake for boys,cake for girls,cake for men,balloons,balloons dubai,balloons abu dhabi,balloons delivery dubai,balloons near me,
balloons and ribbons,balloons bouquet,balloons birthday dubai,balloons birthday delivery,birthday balloons,
balloons decoration,balloons delivery,balloons emoji,ballie balloons,balloons for birthday,balloons for birthday party,balloons for baby shower,balloons for anniversary,
balloons for birthday dubai,balloons fujairah,balloon bouquet,balloon bouquet dubai,balloon bouquet delivery,balloon bouquet delivery dubai,
balloon bouquet for birthday,black and red balloon bouquet,red heart balloon bouquet,red and white balloon bouquet,red and gold balloon bouquet,red flower balloon bouquet,customized balloons abu dhabi,
customized balloons with name,customized balloons online,customized balloons with pictures,customized balloons delivery,customized balloons with logo,customized birthday balloons,
customized birthday balloons delivery,customized happy birthday balloons,customized balloons dubai,customized balloons for birthday,customized helium balloons,
custom balloons miami,customized name balloons,custom shaped balloons,customized heart shaped balloons,customized transparent balloons,customized wedding balloons,surprises for boyfriend,
surprises for girlfriend,surprise for him,surprise shop,surprise birthday,surprise box,surprise birthday cake,surprise birthday party,
surprise birthday gift,surprise birthday ideas for him,surprise cake,surprise cake box,surprise cake ideas,surprise chocolate cake my cafe,
surprise for birthday,surprise for boyfriend birthday,surprise gift,surprise gift for husband,surprise gifts for men,surprise gift box,surprise gift for wife,
surprise gifts for boyfriend,surprise gift for girlfriend,chocolate cake,chocolate chip cookies,chocolate abu dhabi,chocolate and flowers,chocolate box,chocolate bouquet,
chocolate dubai,chocolate delivery dubai,chocolate dessert,chocolate explosion cake,chocolate factory,chocolate factory dubai" />
				</Helmet>
			<Header />
			
			 <div className="page-content bg-white">
			
				<div className="dlab-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + img1 + ")"}}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Our Gifts and Surprises</h1>
							
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
									<li>Our Gifts</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>
				
				<div className="content-block">
		
					<div className="section-full bg-white content-inner"  style={{backgroundImage:"url(" + img4 + ")", backgroundSize:"100%"}}>
						<div className="container">
						<div className="row">{
								gifts.map(ourYachtData =>
								<div className="col-lg-3 col-md-6 col-sm-6">
									<div className="item-box shop-item">
										<Card style={{marginhrefp: '20px',marginTop:'20px'}} onClick={() => history.push(`/detail/${ourYachtData.id}`)}>
											<Card.Img className="item-img" variant="hrefp"  src={ourYachtData.image} alt=""  />
											<Card.Body >
												<Card.Text className="item-info text-center">
												<h4 className="item-title">{ourYachtData.title}</h4>
												<p className="item-price">{ourYachtData.price} </p>
												</Card.Text>
											</Card.Body>
										</Card>
									</div>
								</div>
					) }								
								</div>
						</div>
					</div>

					<div className="section-full content-inner-2 bg-line-top bg-line-bottom" style={{backgroundImage: "url(" + img3 + ")" }}>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="section-head text-center m-b0">
										<div className="icon-bx icon-bx-xl">
											<img src={require('./../../images/icons/icon1.png')} alt="" />
										</div>
										<h3 className="text-white">Top UAE Gifs</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				
				</div>
			</div>
		
			<Footer  />
			</>
		)
	
	
}

export default Ourmenustyle1;