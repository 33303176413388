import React,{Component} from 'react';

import { Link } from 'react-router-dom';
import Menuitem1 from './../Element/Menuitems';
import {Helmet} from "react-helmet";

import Header from './../Layout/Header';
import Footer from './../Layout/Footer';


var img1 = require('./../../images/banner/bnr3.webp');
var img2 = require('./../../images/background/bg5.webp');


class Ourservices extends Component{
	render(){
		return(
			<>
			<Helmet>
			<title>Habibi Yachts | Our Decorations</title>
			<link rel="canonical" href="https://www.habibiyachts.com/decorations" />
        <meta name="keywords" content="habibi Yachts,decorations,yachts decorations,room decorations,car decorations,birthday decorations , habibi Yachts Dubai,habibi.com,habibiyachts.com,yachts,xclusive yachts,yachts in dubai marina,princess yachts,marina yachts,
        dubai marina yachts,luxury yachts,majesty yachts,azimut yachts,royal yachts,yachts dubai,luxurious yachts,
        yachts in dubai,yachts for rent,cozmo yachts,superyacht,boat charter,mega yacht,yacht charter,sailing yacht
        yacht broker,birthday parties on yachts,birthday yachts,birthday in yacht,happy birthday yachts,birthday party in yacht dubai,
        wedding yachts miami,wedding on a yacht dubai,wedding charter yachts,wedding yachts in miami,wedding yacht dubai,
        mega yachts dubai,mega yachts for charter,mega yachts and superyachts,fishing in dubai,fishing abu dhabiyachts rental dubai,yachts rental,yachts rental near me,yachts rental in miami,adonia yachts rental lake powell,
adonia yachts rental,ariel yachts rental,azimut yachts rental,a yacht rental,yachts rental miami beach,yachts and boats rental dubai,nanje rental yachts dubai,
yachts href rent in dubai,yacht rental uae,yachts for rental,yachts for rental near me,yachts for rental in miami,yachts for rental in dubai,
luxury yachts for rental,yachts rental in dubai,yachts rental in abu dhabi,yacht rent dubai,yacht charters dubai,luxury yachts rental,
yachts rental miami,luxury yachts rental miami,luxury yachts rental marina,mega yachts rental,yacht rent in abu dhabi,party yacht rental,yachts rentals,
yachts rentals near me,yachts rentals dubai,vacation rental yachts,wajer yachts rental,xclusive yachts rental dubai,yacht booking abu dhabi,yacht href rent dubai,
yachts dubai rent" />
    </Helmet>
			<Header /> 
			<div className="page-content bg-white">
				
				<div class="dlab-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + img1 + ")", backgroundSize:"cover"}}>
					<div class="container">
						<div class="dlab-bnr-inr-entry">
							<h1 class="text-white">Decorations</h1>
							
							<div class="breadcrumb-row">
								<ul class="list-inline">
									<li><Link to={"./"}><i class="fa fa-home"></i></Link></li>
									<li>Our Decorations</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>
				
				<div className="content-block">
					
					<div className="section-full bg-white content-inner" style={{backgroundImage:"url(" + img2 + ")", backgroundSize:"100%"}}>
						
							
							
							<Menuitem1 />
								
							
						
					</div>
					
				</div>
				<Footer />
			</div>
			
		</>
		)
	}
	
}
export default Ourservices;