import React, { Component } from 'react';
import { a } from 'react-router-dom';
import Card from 'react-bootstrap/Card';


class Menuitem1 extends Component {
	render() {
		return (

			<div className="page-content bg-white">
				<div className="content-block">
					<div className="section-full content-inner bg-gray-light">
						<div className="container">
							<div className="row">

								<div className="col-lg-3 col-md-6 col-sm-6">
									<div className="item-box shop-item">
										<Card >
											<Card.Img className="item-img" variant="top" src={require('./../../images/services/12.webp')} />
											<Card.Body>
												<Card.Text className="item-info text-center">
													<h4 className="item-title">
														<span> Beach Decorations </span>
													</h4>
													<p className="item-price"> AED 999 </p>
												</Card.Text>
												<a href={'https://wa.me/9710545850896'} target="_blank" rel="noopener noreferrer" className="btn btnhover"><i className="ti-shopping-cart m-r5"></i> Book Now </a>
											</Card.Body>
										</Card>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 col-sm-6">
									<div className="item-box shop-item">
										<Card >
											<Card.Img className="item-img" variant="top" src={require('./../../images/services/9.webp')} />
											<Card.Body>
												<Card.Text className="item-info text-center">
													<h4 className="item-title">
														<span> Marry me Decoration </span>
													</h4>
													<p className="item-price"> AED 800 </p>
												</Card.Text>
												<a href={'https://wa.me/9710545850896'} target="_blank" rel="noopener noreferrer" className="btn btnhover"><i className="ti-shopping-cart m-r5"></i> Book Now </a>
											</Card.Body>
										</Card>
									</div>
								</div>

								<div className="col-lg-3 col-md-6 col-sm-6">
									<div className="item-box shop-item">
										<Card >
											<Card.Img className="item-img" variant="top" src={require('./../../images/services/13.webp')} />
											<Card.Body>
												<Card.Text className="item-info text-center">
													<h4 className="item-title">
														<span> Special Decoration </span>
													</h4>
													<p className="item-price"> AED 800 </p>
												</Card.Text>
												<a href={'https://wa.me/9710545850896'} target="_blank" rel="noopener noreferrer" className="btn btnhover"><i className="ti-shopping-cart m-r5"></i> Book Now </a>
											</Card.Body>
										</Card>
									</div>
								</div>						

								<div className="col-lg-3 col-md-6 col-sm-6">
									<div className="item-box shop-item">
										<Card >
											<Card.Img className="item-img" variant="top" src={require('./../../images/services/7.webp')} />
											<Card.Body>
												<Card.Text className="item-info text-center">
													<h4 className="item-title">
														<span> Room Decorations </span>
													</h4>
													<p className="item-price"> AED 499 </p>
												</Card.Text>
												<a href={'https://wa.me/9710545850896'} target="_blank" rel="noopener noreferrer" className="btn btnhover"><i className="ti-shopping-cart m-r5"></i> Book Now </a>
											</Card.Body>
										</Card>
									</div>
								</div>

								
								



							</div>
						</div>
					</div>

				</div>
			</div>

		)
	}
}

export default Menuitem1;